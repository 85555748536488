import React from "react";
import "./YesNo.scss";

function YesNo(props) {
	const name = props.name;
	return (
		<div id="YesNo_Container">
			<div className="con">
				<label id="check">
					<input
						type="radio"
						name={name}
						value={"Yes"}
					/>
					<svg
						viewBox="0 0 64 64"
						height="1em"
						width="1em"
					>
						<path
							d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
							pathLength="575.0541381835938"
							className="path"
						></path>
					</svg>
				</label>
				<p>Yes</p>
			</div>
			<div className="con">
				<label id="check">
					<input
						type="radio"
						name={name}
						value={"No"}
					/>
					<svg
						viewBox="0 0 64 64"
						height="1em"
						width="1em"
					>
						<path
							d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
							pathLength="575.0541381835938"
							className="path"
						></path>
					</svg>
				</label>
				<p>No</p>
			</div>
		</div>
	);
}

export default YesNo;
