import React, { useState } from "react";
import "./HomePage.scss";
import Rating from "./Rating/Rating";
import YesNo from "./YesNo/YesNo";
import ScrollAnimation from "./ScrollAnimation/ScrollAnimation";
import Check from "./Check/Check";

function HomePage(props) {
	const data = props.data;
	const [check, setCheck] = useState(false);
	const toggle = () => {
		if (check) {
			setCheck(false);
		}
		if (!check) {
			setCheck(true);
		}
	};

	return (
		<div id="HomePage_Container">
			<div id="Banner">
				<img
					src="/assets/Group 3.png"
					alt=""
				/>

				<h1>Share your valuable experience with us</h1>
				<p>Your insights matter, and we’re excited to hear about how we’ve made a difference in your brand</p>
				<div id="srl_Container">
					<ScrollAnimation />
				</div>
			</div>
			<div id="Testimonial">
				<form action={data.Link}>
					<h2>Testimonial Feedback</h2>
					<div id="info">
						<input
							type="text"
							name={data.FullName}
							placeholder="Full Name"
						/>
						<input
							type="email"
							name={data.Email}
							placeholder="Email"
						/>
						<select
							placeholder="Work Done"
							name={data.WorkDone}
							id=""
						>
							<option
								value=""
								// disabled
								defaultChecked
							>
								Work Done
							</option>
							<option value="3D">3D</option>
							<option value="UXUI">UXUI</option>
							<option value="Branding">Branding</option>
							<option value="All Of The Above">All Of The Above</option>
						</select>
					</div>
					<label>
						01. How would you describe your overall experience working with us?
						<Rating
							name={data.Q1}
							num={1}
						/>
					</label>
					<label>
						02. Can you share a few highlights or memorable moments from your journey with us?
						<input
							type="text"
							placeholder="Type Here ..."
							name={data.Q2}
						/>
					</label>
					<label>
						03. Did our services/products meet or exceed your expectations?
						<YesNo name={data.Q3} />
					</label>
					<div id="LQ4">
						<p>04. In what ways did our services/products meet or exceed your expectations?</p>
						<div id="Q4">
							<Check
								name={data.Q4}
								text={"Exceptional Quality"}
							/>
							<Check
								name={data.Q4}
								text={"Timely Delivery"}
							/>
							<Check
								name={data.Q4}
								text={"Outstanding Value"}
							/>
							<Check
								name={data.Q4}
								text={"Attention to Detail"}
							/>
							<Check
								name={data.Q4}
								text={"Customization Options"}
							/>
						</div>
					</div>
					<label>
						05. How would you describe the level of professionalism and expertise displayed by our team?
						<Rating
							name={data.Q5}
							num={2}
						/>
					</label>
					<label>
						06. Did you feel well-supported and informed throughout the entire journey?
						<YesNo name={data.Q6} />
					</label>
					<label>
						07. How do you feel about the final outcome/results of our collaboration?
						<Rating
							name={data.Q7}
							num={3}
						/>
					</label>
					<label>
						08. Would you recommend Stitches Studios to others based on your experience?
						<YesNo name={data.Q8} />
					</label>
					<label>
						09. Were there any specific challenges or concerns you had during the process, and how did we address them?
						<input
							type="text"
							placeholder="Type Here ..."
							name={data.Q9}
						/>
					</label>
					<div id="Check_Container">
						<label id="check">
							<input
								type="checkbox"
								onChange={toggle}
							/>
							<svg
								viewBox="0 0 64 64"
								height="1em"
								width="1em"
							>
								<path
									d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
									pathLength="575.0541381835938"
									className="path"
								></path>
							</svg>
						</label>
						<p>
							<strong>Note:</strong> Upon submitting this testimonial, you grant us authorization to potentially feature
							your gracious feedback on both our website and social media channels. This enables others to discover the
							affirmative influence of Stitches Studios.
						</p>
					</div>
					<button
						id="submit_btn"
						type="submit"
						disabled={!check}
					>
						Submit
					</button>
				</form>
			</div>
		</div>
	);
}

export default HomePage;
