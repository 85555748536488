import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import Page404 from "./components/Page404/Page404";
import data from "./data.json";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route
						path="/"
						element={<HomePage data={data} />}
					/>
					<Route
						path="*"
						element={<Page404 />}
					/>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
