import React from "react";
import "./Rating.scss";

function Rating(props) {
	const name = props.name;
	const num = props.num;
	return (
		<div className="rating">
			<input
				value="5"
				name={name}
				id={`star5${num}`}
				type="radio"
			/>
			<label htmlFor={`star5${num}`}></label>
			<input
				value="4"
				name={name}
				id={`star4${num}`}
				type="radio"
			/>
			<label htmlFor={`star4${num}`}></label>
			<input
				value="3"
				name={name}
				id={`star3${num}`}
				type="radio"
			/>
			<label htmlFor={`star3${num}`}></label>
			<input
				value="2"
				name={name}
				id={`star2${num}`}
				type="radio"
			/>
			<label htmlFor={`star2${num}`}></label>
			<input
				value="1"
				name={name}
				id={`star1${num}`}
				type="radio"
			/>
			<label htmlFor={`star1${num}`}></label>
		</div>
	);
}

export default Rating;
